var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"value":_vm.selected,"headers":_vm.headers,"items":_vm.items,"items-per-page":10,"search":_vm.search,"loading":_vm.loading,"loading-text":'Chargement en cours...',"hide-default-footer":!!_vm.$slots.footer,"show-select":"","item-key":"_id","sort-by":"name","multi-sort":""},on:{"input":function($event){return _vm.$emit('update:selected', $event)}},scopedSlots:_vm._u([{key:"item.file",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openDocument(item.file)}}},[_c('v-icon',[_vm._v("description")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":("" + (item._id)),"append":"","icon":""}},[_c('v-icon',[_vm._v("edit")])],1)]}},(_vm.$slots.footer)?{key:"footer",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-row',{staticClass:"px-2",staticStyle:{"border-top":"thin solid rgba(0, 0, 0, 0.12)"},attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_vm._t("footer")],2),_c('v-col',[_c('v-data-footer',_vm._g(_vm._b({staticStyle:{"border":"none"}},'v-data-footer',props,false),on))],1)],1)]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }