<template>
  <ag-modal-container
    title="Documents supprimées"
    width="900px"
    @close="back"
  >
    <div
      v-if="!loadingData && !documentList.length"
      class="text-center title mt-5"
    >
      Aucun élément
    </div>

    <document-list
      v-else
      :value="documentList"
      :selected.sync="selectedDocumentList"
      :loading="loadingData"
      light
    />

    <template v-slot:footer>
      <v-spacer />
      <ag-confirm-dialog
        title="Confirmation"
        :message="restoreMessage"
        cancel-label="Annuler"
        confirm-label="Restaurer"
        validation-btn-color="accent"
        @confirm="restoreSelected"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :loading="loadingRestore"
            :disabled="!selectedDocumentList.length || loadingRestore"
            color="accent"
            class="ml-5"
            v-bind="attrs"
            v-on="on"
            v-text="'Restaurer'"
          />
        </template>
      </ag-confirm-dialog>
    </template>
  </ag-modal-container>
</template>

<script>
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'

import AgModalContainer from 'src/components/common/AgModalContainer'
import AgConfirmDialog from 'src/components/common/AgConfirmDialog'
import DocumentList from '../components/DocumentList'

export default {
  name: 'DocumentRestoreContainer',
  components: {
    AgModalContainer,
    AgConfirmDialog,
    DocumentList,
  },
  data () {
    return {
      documentList: [],
      selectedDocumentList: [],
      loadingData: false,
      loadingRestore: false,
    }
  },
  computed: {
    restoreMessage () {
      const count = this.selectedDocumentList.length
      return `Souhaitez-vous restaurer ${count} document(s)`
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler ({ name }) {
        if (name === 'DocumentRestore') {
          this.fetchData()
        }
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    back () {
      this.$router.back()
    },
    async fetchData () {
      this.loadingData = true
      this.documentList = await Repositories.document.getList({ archived: true })
      this.loadingData = false
    },
    async restoreSelected () {
      try {
        this.loadingRestore = true
        const count = this.selectedDocumentList.length
        await Repositories.document.restore(
          this.selectedDocumentList.map(select => select._id),
        )

        this.selectedDocumentList = []
        this.fetchData()

        this.showSuccessNotification(`Vous avez restoré ${count} document(s)`)
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingRestore = false
      }
    },
  },
}
</script>
